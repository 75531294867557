<template>
    <section class="mt-3">
        <div class="row mx-0">
            <div class="col px-3 py-3 bg-white shadow-15 br-12">
                <div class="row mx-0">
                    <div class="bg-general d-middle-center f-14 f-600 text-white rounded-circle" style="width:26px;height:26px;">
                        2
                    </div>
                    <p class="col text-general f-18 f-600 mb-3">
                        Datos Generales
                    </p>
                </div>
                <div class="row mx-0">
                    <div class="col-6">
                        <div class="row mx-0">
                            <i class="icon-cupon text-general" />
                            <div class="col">
                                <p class="text-general f-15">Nombre del cupon:</p>
                                <p class="f-14">{{ bono.nombre }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="row mx-0">
                            <div class="col">
                                <p class="text-general f-15">Codigo:</p>
                                <p class="f-14">{{ bono.codigo }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 my-3">
                        <div class="row mx-0">
                            <i class="icon-message-text-outline text-general f-20" />
                            <div class="col pl-2">
                                <p class="text-general f-15">
                                    Mensaje del cupón
                                </p>
                                <p class="f-14">
                                    {{ bono.descripcion }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 d-middle">
                        <i class="icon-sale f-20 text-general" />
                        <div v-if="bono.descuento_tipo == 1" class="col pl-2">
                            <p class="f-15 text-general">
                                Descuento por porcentaje:
                            </p>
                            <p class="f-14">
                                {{ bono.descuento }}%
                            </p>
                        </div>
                        <div v-if="bono.descuento_tipo == 2" class="col pl-2">
                            <p class="f-15 text-general">
                                Descuento valor:
                            </p>
                            <p class="f-14">
                                {{ separadorNumero(bono.descuento) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-middle">
                        <i class="icon-plus f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Cupones otorgados
                            </p>
                            <p class="f-14">
                                {{ bono.hijo_cantidad }} Cupones
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-3 py-3 bg-white shadow-15 br-12 ml-3">
                <p class="text-general f-600 mb-3">
                    Condiciones para disponibilidad del {{ cupon.destinatario == 1 ? $config.vendedor : $config.cliente }} 
                </p>
                <div class="row mx-0">
                    <div class="col-12 d-middle">
                        <i class="icon-receipt f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Cantidad de pedidos realizados:
                            </p>
                            <p class="f-14">
                                Entre {{ bono.pedidos_minimo || 0 }} y {{ bono.pedidos_maximo || 'Sin limite' }} Pedidos
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col px-3 py-3 bg-white br-12 shadow-15">
                <p class="text-general f-600 mb-3">
                    Limites del uso del cúpon
                </p>
                <div class="row mx-0">
                    <div class="col-6 mt-3 d-middle">
                        <i class="icon-currency-usd-circle f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Valor mínimo de compra
                            </p>
                            <p class="f-14">
                                {{ separadorNumero(bono.compra_minima) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-middle">
                        <i class="icon-cash-plus f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Descuento máximo aplicado:
                            </p>
                            <p class="f-14">
                                {{ separadorNumero(bono.descuento_maximo) }}
                            </p>
                        </div>
                    </div>
                    <div class="col-6 mt-3 d-middle">
                        <i class="icon-calendar-clock f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Vigencia
                            </p>
                            <p class="f-14">
                                {{ bono.hijo_vigencia }} Dias
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col px-3 py-3 bg-white br-12 shadow-15 ml-3">
                <p class="text-general f-600 mb-3">
                    Productos aplicados
                </p>
                <div class="row mx-0">
                    <div class="col-12 d-middle">
                        <i class="icon-package-variant-closed f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                {{ bono.productos_oferta ? 'Incluir' : 'Excluir' }} productos en oferta
                            </p>
                        </div>
                    </div>
                    <div v-if="bono.todo_catalogo" class="col-6 d-middle">
                        <i class="icon-cart-outline f-20 text-general" />
                        <div class="col pl-2">
                            <p class="f-15 text-general">
                                Aplica todo Catalogo
                            </p>
                        </div>
                    </div>
                    <div v-else class="col-6 mb-3">
                        <div class="d-middle">
                            <i class="icon-format-list-bulleted-type f-20 text-general" />
                            <p class="f-15 text-general">
                                Aplica las categorías:
                            </p>
                        </div>
                        <p v-for="(cat,index) in bono.categorias" :key="index" class="f-14 pl-2">
                            {{ cat.categoria }}
                        </p>
                        <!-- <div class="row mx-0 justify-content-end">
                            <div class="col-6">
                                <div class="col pl-2">

                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    computed:{
        ...mapGetters({
            bono: 'cupones/cupones/datosBono',
            cupon: 'cupones/cupones/datosDetalle',
        }),
    }

}
</script>
<style lang="scss" scoped>
.shadow-15{
    box-shadow: 0px 1px 4px #15223214;
}
</style>
